.side-drawer {
  height: 100%;
  background: #f5f5f5;
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer ul {
  padding: 10%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side-drawer li {
  margin: 0.5rem 0;
}

.side-drawer a {
  text-decoration: none;
  font-size: 1.2rem;
}

@media screen and (min-width: 759px) {
  .side-drawer {
    display: none;
  }
}

.subSection {
  color: black;
  padding-left: 18px;
  font-size: 16px;
}
