.toolbar {
  padding: 0 16%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  border-bottom: 1px solid #7f7f7f80;
  height: 100px;
  margin-bottom: 2rem;
  z-index: 100;
}

.toolbar_logo {
  padding-left: 14%;
}

@media screen and (max-width: 760px) {
  .toolbar {
    padding: 0 1rem;
  }
  .toolbar_title {
    font-size: 13px;
    line-height: 16px;
  }
  .toolbar_logo {
    padding-left: 60%;
  }
}

.toolbar_navigator {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.logo_image {
  width: 100%;
  height: 200%;
  border: 1px solid red;
}

.toolbar_logo a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
}

@media screen and (min-width: 760px) {
  .logo_image {
    max-height: 80px;
    width: 80px;
  }
}

.toolbar_title {
  padding-left: 18px;
  display: flex;
  flex-direction: column;
}

.spacer {
  width: 24%;
}

.toolbar_navigation-items ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  color: var(--color-navbarText);
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
}

.toolbar_navigation-items a:active {
  color: rgb(162, 179, 140);
}

@media screen and (max-width: 760px) {
  .toolbar_navigation-items {
    display: none;
  }
}

@media screen and (min-width: 759px) {
  .toggle-btn {
    display: none;
  }
}

/* Dropdown Button */
.dropbtn {
  border: none;
  cursor: pointer;
  color: var(--color-navbarText);
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  border: 1px solid #ddd;
  z-index: 1;
  border-radius: 5px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
